import React, { useState, useEffect } from 'react'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import CategoryAdd from '../../components/Dashboard/CategoryAdd/CategoryAdd'
import CategoryList from '../../components/Dashboard/CategoryList/CategoryList'
import ErrorPage from '../ErrorPage/ErrorPage'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"

import { useAuthUser } from 'react-auth-kit';
import Search from '../../components/Dashboard/Search/Search'


const Categories = () => {

    const auth = useAuthUser()
    document.title = 'Dash Categories';

    const [asideMenu, setAsideMenu] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);


    if (auth().status == 'Admin' || auth().status == 'Urednik') {

        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                    <Search />

                    <div className="main__section category__layout-grid">
                        {/*Ovde idu komponente svake stranice. iznad divovi ostaju isti na svakoj stranici*/}
                        <CategoryAdd />
                        <CategoryList />

                    </div>
                </div>
            </div>
        )

    } else {
        return <ErrorPage notAdmin /> 
    }
}

export default Categories