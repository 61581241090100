import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useAuthHeader } from 'react-auth-kit';
// import jwt from 'jwt-decode'


import api from '../../../api/api'
import authsession from "../../../utils/authsession";

export const getUsers = createAsyncThunk("DASH_USERS/GET_ALL_USERS", async () => {
    
    // const authHeader = useAuthHeader()
    // const authtoken = auth(); 

    try {
        const { data } = await api({
            method: 'get',
            headers: { 'Authorization': authsession() },
            url: `usersc.php?fun=read&lokacija=dash`,
        });
        return data;
    } catch (error) {
        return error.message;
    }
});


const initialState = {
    usersData: [],
    usersLoading: false,
    usersMessage: '',

    dataUsersUpdate: [],
    dataUsersLoading: true,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        editUsers(state, action) {
            state.dataUsersUpdate = action.payload;
            state.dataUsersLoading = false;
        },
    },
    extraReducers: {

        [getUsers.pending]: (state, action) => {
            state.usersLoading = true;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.usersLoading = false;
            state.usersData = action.payload;
        },
        [getUsers.rejected]: (state, action) => {
            state.usersLoading = false;
            state.usersMessage = action.payload;
        },
    },
});

// export const allData = (state) => state.categories.data;
export const { editUsers } = usersSlice.actions
export default usersSlice.reducer;