import React, { useState, useEffect } from 'react'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage';
import jwt from 'jwt-decode'

import SubCategoryLogList from '../../components/Dashboard/LogLists/SubCategoryLogList'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"
import OrdersLog from '../../components/Dashboard/LogLists/OrdersLog';

import { useAuthUser } from 'react-auth-kit';


const DashSubOrdersLog = () => {

    const auth = useAuthUser();
    document.title = 'Log Orders';

    const [asideMenu, setAsideMenu] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);


    if (auth().status == 'Admin') {
        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />

                    <div className="main__section-nosearch">
                        <OrdersLog />


                    </div>
                </div>
            </div>
        )
    } else {
        return <ErrorPage notAdmin />
    }
}

export default DashSubOrdersLog