import React, { useEffect, useState } from 'react'
import api from '../../../api/api';
import { RiDeleteBinLine, RiEditBoxLine, RiSearch2Line } from 'react-icons/ri'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';

import { useSelector, useDispatch } from 'react-redux';
import { getDashSubCategory, editSubCategory } from "../../../redux/features/subcategoriesdash/subCategoriesDahsSlice";
import Loader from '../../Loader/Loader';
import deleteSubcategoryFun from '../../../utils/deleteSubcategoryFun'

import { useAuthUser } from 'react-auth-kit';


const SubCategoryList = () => {

    const auth = useAuthUser()

    const subCategoryList = useSelector((state) => state.subCategoryDashList)
    const { subLoading, subData, subMessage } = subCategoryList;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashSubCategory());
    }, [dispatch]);


    // Stanje za Update kategorije
    const [idSubkat, setIdSubkat] = useState({ podkat_id: '', })

    const updateSubcategory = (subcategory) => {
        dispatch(editSubCategory({
            subcategory
        }, [dispatch]))
    }


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }

    
    // Inicijalno stanje za brisanje podkategorije
    const [brisanjePodkategorije, setBrisanjePodkategorije] = useState({
        id_podkat: '',
        ime_podkat_sr: '',
        ime_podkat_en: '',
    });

    const deleteSubcategory = (id, ime_sr, ime_en) => {

        if (window.confirm(`Da li ste sigurni da želite da obrišete podkategoriju '${ime_sr}'?`)) {

            const sendData = {
                id_podkat: id,
                ime_podkat_sr: ime_sr,
                ime_podkat_en: ime_en,
                user: auth().uid
            }

            deleteSubcategoryFun(api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getDashSubCategory, setBrisanjePodkategorije)
        }
    }

    
    const search = () => {

    }




    return (
        <div className='category__container category-list'>
            <div className="category__container-header">
                <h2>Lista Podkategorija</h2>
            </div>

            <div className="category__container-inputs">

                <div className="table mt-1">
                    <table >
                        <thead>
                            <tr>
                                <th className='column-x-small'>Id</th>
                                <th className='column-medium'>Naziv Sr</th>
                                <th className='column-medium'>Naziv En</th>
                                <th className='column-medium'>Kategorija</th>
                                <th className='column-large'>Kreirana</th>
                                <th className='column-small'>Kreirao</th>
                                <th className='column-small options'>Opcije</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                subLoading ? <tr><td><p> Loading... </p></td></tr> : subData.greska ? <tr><td><h3 className='color-danger'>{subData.greska}</h3></td></tr> :

                                subData.length !== 0 ?
                                    subData.map((item, idx) => {

                                        return (
                                            <tr key={idx}>
                                                <td className='column-x-small'>{item.podkat_id}</td>
                                                <td className='column-medium'>{item.podkat_naziv_sr}</td>
                                                <td className='column-medium'>{item.podkat_naziv_en}</td>
                                                <td className='column-medium info-cell'>{item.kat_naziv_sr}</td>
                                                <td className='column-large'>{item.podkat_dkreiranja}</td>
                                                <td className='column-small'>{item.korisnik_ime}</td>
                                                {/*<td className='option '><RiEditBoxLine className='icon-dash-info icon-dash-info-hover icon-small ml-15' onClick={() => setIdKat(item.kat_id)} /></td>*/}


                                                <td className='column-small options'>
                                                    <RiEditBoxLine onClick={() => updateSubcategory(item)} className='icon-dash-info icon-small' />
                                                    <RiDeleteBinLine onClick={() => deleteSubcategory(item.podkat_id, item.podkat_naziv_sr, item.podkat_naziv_en)} className='icon-dash-danger icon-small'
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }) : 
                                        <tr>
                                            <td className='column-x-small'>No data</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SubCategoryList