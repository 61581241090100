import authsession from './authsession';

const editCategory = ( api, sendData, notifyError, notifyInfo, notifySuccess, setKatSrUpdate, setKatEnUpdate, dispatch, setIsLoading, getDashCategories ) => {

  api({
      method: 'post',
      headers: { 'Authorization': authsession() },
      url: 'categoryc.php?fun=update',
      data: sendData,
    })
      .then((response) => {

        if (response.data.uspesno) {

          notifySuccess(response.data.uspesno);
          setIsLoading(false)
          setKatSrUpdate('');
          setKatEnUpdate('');
          dispatch(getDashCategories());

        } else if (response.data.greska) {

            notifyError(response.data.greska);
            setIsLoading(false)
        } else if (response.data.info) {

            setIsLoading(false)
            notifyInfo(response.data.info);
        }
      })

}

export default editCategory