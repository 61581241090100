import authsession from './authsession';

const orderDeleteFun = ( api, sendData, notifySuccess, notifyError, notifyInfo, dispatch, getDashOrders, setDetailsWindow, setModal ) => {


    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'orderc.php?fun=delete',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                dispatch(getDashOrders());
                setDetailsWindow(false);
                setModal(false)
            } else if (response.data.greska) {
                notifyError(response.data.greska);

            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default orderDeleteFun