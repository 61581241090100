import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from '../../../api/api'
import authsession from "../../../utils/authsession";


export const getProductsDash = createAsyncThunk("GET_PRODUCTS_DASH/GET_ALL_PRODUCTS_DASH", async () => {

    try {
        const { data } = await api({
            method: 'get',
            headers: { 'Authorization': authsession() },
            url: 'productc.php?fun=read&lokacija=dash',
        });
        return data;
    } catch (error) {
        return error.message;
    }
});

const initialState = {
    data: [],
    loading: false,
    message: '',

    dataProductUpdate: [],
}

export const productsDashSlice = createSlice({

    name: 'product',
    initialState,
    reducers: {
        editProduct(state, action) {
            state.dataProductUpdate = action.payload
        },
    },
    extraReducers: {
        [getProductsDash.pending]: (state, action) => {
            state.loading = true;
        },
        [getProductsDash.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getProductsDash.rejected]: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
    },
});

// export const allData = (state) => state.registration.data;
export const { editProduct } = productsDashSlice.actions
export default productsDashSlice.reducer;