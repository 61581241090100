import authsession from './authsession';

const addProduct = ( api, formData, setLoading, notifyError, notifyInfo, notifySuccess, setSlike, setImeSr, setImeEn, setPodkatpr, setCenapr, setSnizenaCena, setOpisEn, setOpisSr, setOdrzavanje, setVelicina, setMaterijalEn, setMaterijalSr, setKolicina, setBojaEn, setBojaSr, setTabela ) => {

  api({
    method: 'post',
    headers: { 'Authorization': authsession() },
    url: 'productc.php?fun=add',
    data: formData,
  })
  .then((response) => {
    if (response.data.uspesno) {
        setLoading(false)

        notifySuccess(response.data.uspesno);

        setSlike([]);
        setImeSr('');
        setImeEn('');
        setPodkatpr('');
        setCenapr('');
        setSnizenaCena('');
        setOpisSr('');
        setOpisEn('');
        setOdrzavanje([]);
        setVelicina('36');
        setMaterijalSr('');
        setMaterijalEn('');
        setKolicina('1');
        setBojaSr('');
        setBojaEn('');
        setTabela('z');

      } else if (response.data.greska) {
        notifyError(response.data.greska);
        setLoading(false)
      } else if (response.data.info) {
        notifyInfo(response.data.info);
        setLoading(false)
      }
    })
}

export default addProduct