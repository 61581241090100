import React from 'react'

//Styling
import { IoCloseOutline } from 'react-icons/io5';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';

const Modal = ({ modal, setModal, modalId, setDetailsWindow }) => {

    const closeModal = () => {
        setModal(false);
        setDetailsWindow(false)
    }

    switch (modalId) {

        case 'orderDetails':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModal} ></div>
            )
        case 'productDetails':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModal} ></div>
            )
        case 'userDetails':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModal} ></div>
            )
    }
}

export default Modal