import authsession from './authsession';

const deleteSubcategory = ( api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getDashSubCategory, setBrisanjePodkategorije ) => {

 
    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'subcategoryc.php?fun=delete',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                setBrisanjePodkategorije({ id_kat: '', ime_podkat_sr: '', ime_podkat_en: '' })
                dispatch(getDashSubCategory());

            } else if (response.data.greska) {
                notifyError(response.data.greska);

            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })

}

export default deleteSubcategory