import React, { useState, useEffect } from 'react'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"
import DetailsWindow from '../../components/Dashboard/DetailsWindow/DetailsWindow'
import Modal from '../../components/Modal/Modal'
import SingleProduct from '../../components/Dashboard/SingleProduct/SingleProduct'
import Filters from '../../components/Dashboard/Filters/Filters'


import { useAuthUser } from 'react-auth-kit';
import Search from '../../components/Dashboard/Search/Search';

const DashProductList = () => {


    const auth = useAuthUser();
    document.title = 'Dash Product List';

    const [asideMenu, setAsideMenu] = useState(false);
    const [detailsWindow, setDetailsWindow] = useState(false);
    const [productOption, setProductOption] = useState('');
    const [modal, setModal] = useState(false)
    const [modalId, setModalId] = useState('');

    const dispatch = useDispatch();
    

    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);



    if (auth().status == 'Admin' || auth().status == 'Urednik') {

        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">

                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />

                    <Search />

                    <div className="main__section">

                        {/* <Filters /> */}

                        <SingleProduct 
                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            productOption={productOption}
                            setProductOption={setProductOption}
                            modal={modal}
                            setModal={setModal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <DetailsWindow
                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            productOption={productOption}
                            setProductOption={setProductOption}
                            setModal={setModal}
                            modal={modal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <Modal modal={modal} setModal={setModal} modalId={modalId}   setDetailsWindow={setDetailsWindow} />

                    </div>
                </div>
            </div>
        )
    } else {
        return <ErrorPage notAdmin />
    }
}

export default DashProductList