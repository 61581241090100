import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiEditBoxLine, RiCheckboxCircleLine } from 'react-icons/ri'
import api from '../../../api/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders, editOrder } from "../../../redux/features/orders/ordersSlice"
import orderDeleteFun from '../../../utils/orderDeleteFun';
import orderEdit from '../../../utils/orderEdit';

import { useAuthUser } from 'react-auth-kit';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;


const OrderDetailsEdit = ({ setDetailsWindow, modal, setModal }) => {


    const auth = useAuthUser()

    const ordersList = useSelector((state) => state.ordersList)
    const { dataOrderUpdate } = ordersList;

    const { proizvod_cena,
        por_proizvodi, proizvod_ime, slika_ime,por_id, por_ime,
        por_prezime, por_email, por_broj_tel, por_ulica,
        por_broj_ulaz,por_broj_stan, por_drzava,por_grad, por_postanski_broj,
        por_preuzimanje, por_placanje, por_vreme_kreiranja, 
        korisnici_korisnik_id, por_status } = dataOrderUpdate.order;


    const splitSlike = slika_ime.split(',');
    const splitIme = proizvod_ime.split(',');
    const splitPor = por_proizvodi.split(',');
    const price = proizvod_cena.split(',');


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashOrders());
    }, [dispatch]);


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }

    
    // Inicijalno stanje za brisanje ordera
    const [ordertDelete, setOrderDelete] = useState({
        id_por: '',
    });

    const deleteOrder = (id) => {
        if (window.confirm('Da li ste sigurni da želite da obrišete porudžbinu?')) {

            const sendData = {
                id_por: id,
                user: auth().uid
            }

            orderDeleteFun(api, sendData, notifySuccess, notifyError, notifyInfo, dispatch, getDashOrders, setModal, setDetailsWindow)
        }
    }


    // Order change -> Update
    const [ime, setIme] = useState(por_ime);
    const [prezime, setPrezime] = useState(por_prezime);
    // const [email, setEmail] = useState(por_email);
    const [ulica, setUlica] = useState(por_ulica);
    const [brojUlaza, setBrojUlaza] = useState(por_broj_ulaz);
    const [brojStana, setBrojStana] = useState(por_broj_stan);
    const [drzava, setDrzava] = useState(por_drzava);
    const [grad, setGrad] = useState(por_grad);
    const [postanskiBroj, setPostanskiBroj] = useState(por_postanski_broj);
    const [brojTelefona, setBrojTelefona] = useState(por_broj_tel);
    const [preuzimanje, setPreuzimanje] = useState(por_preuzimanje);
    const [placanje, setPlacanje] = useState(por_placanje);
    const [status, setStatus] = useState(por_status);
    const [porId, setPorId] = useState(por_id);


    const updateOrder = (e) => {
        e.preventDefault()

        const sendData = {
            ime: ime,
            prezime: prezime,
            ulica: ulica,
            brojUlaza: brojUlaza,
            brojStana: brojStana,
            drzava: drzava,
            grad: grad,
            postanskiBroj: postanskiBroj,
            brojTelefona: brojTelefona,
            preuzimanje: preuzimanje,
            placanje: placanje,
            status: status,
            user: auth().uid,
            porId: porId,
        }

        orderEdit(api, sendData, notifySuccess, notifyError, notifyInfo, dispatch, getDashOrders, setDetailsWindow, setModal)
    }



    const orderStatus = (status) => {
        switch (status) {
            case 'ceka-slanje':
                return (
                    <div className='color-danger-muted '>
                        <label htmlFor="">Status porudžbine</label>
                        <select name="vestatus" onChange={(e) => setStatus(e.target.value)}>
                            <option defaultValue={status}>{status}</option>
                            <option defaultValue="Poslato">poslato</option>
                            <option defaultValue="Isporuceno">isporuceno</option>
                        </select>
                    </div>
                )
            case 'isporuceno':
                return (
                    <div className='color-success-muted '>
                        <label htmlFor="">Status porudžbine</label>
                        <select name="vestatus" onChange={(e) => setStatus(e.target.value)}>
                            <option defaultValue={status}>{status}</option>
                        </select>
                    </div>
                )
            case 'poslato':
                return (
                    <div className='color-info-muted '>
                        <label htmlFor="">Status porudžbine</label>
                        <select name="status" onChange={(e) => setStatus(e.target.value)}>
                            <option defaultValue={status}>{status}</option>
                            <option defaultValue="Isporuceno">isporuceno</option>
                        </select>
                    </div>
                )
            default:
                return (
                    <div className=''>
                        <label htmlFor="">Status porudžbine</label>
                        <input type="text" placeholder={status} />
                    </div>
                )
        }
    }


    const orderImages = (splitSlike) => {

        let arr = [];
        let maxVal = splitSlike.length / 4;
        let delta = Math.floor(splitSlike.length / maxVal);

        for (let i = 0; i < splitSlike.length; i = i + delta) {
            arr.push(splitSlike[i]);
        }

        return arr.map((item, idx) => {
            return (
                <div key={idx} className='details__products-img-content'>
                    <img src={slike + item} alt="" />
                </div>
            )
        })
    }

    const orderPrice = (price) => {

        let arr = [];
        let maxVal = price.length / 4;
        let delta = Math.floor(price.length / maxVal);

        for (let i = 0; i < price.length; i = i + delta) {
            arr.push(price[i]);
        }

        let sum = 0;

        arr.forEach(element => {
            sum += Number(element)
        });

        return (
            <h3>{sum} ,00 din</h3>
        )
    }


    return (
        <div className="category__container-inputs order__dash-details">
            <h3 className='accent'>Izmena porudžbine</h3>

            <div className='details__container'>

                <div className='details__form'>
                    <form onSubmit={updateOrder}>
                        <div className='form__inputs mr-2'>
                            <label htmlFor="">Ime</label>
                            <input type="text" name="subkat_sr" defaultValue={por_ime} onChange={(e) => setIme(e.target.value)} />

                            <label htmlFor="">Prezime</label>
                            <input type="text" name="subkat_en" defaultValue={por_prezime} onChange={(e) => setPrezime(e.target.value)} />

                            <label htmlFor="">Email</label>
                            <input type="text" name="subkat_en" placeholder={por_email} disabled />

                            <label htmlFor="">Broj telefona</label>
                            <input type="text" name="subkat_en" defaultValue={por_broj_tel} onChange={(e) => setBrojTelefona(e.target.value)} />
                        </div>

                        <div className='form__inputs mr-2'>
                            <label htmlFor="">Ulica</label>
                            <input type="text" name="subkat_en" defaultValue={por_ulica} onChange={(e) => setUlica(e.target.value)} />

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-2'>
                                    <label htmlFor="">Broj ulaza</label>
                                    <input type="text" name="subkat_en" defaultValue={por_broj_ulaz} onChange={(e) => setBrojUlaza(e.target.value)} />
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">Broj stana</label>
                                    <input type="text" name="subkat_en" defaultValue={por_broj_stan} onChange={(e) => setBrojStana(e.target.value)} />
                                </div>
                            </div>

                            <label htmlFor="">Država</label>
                            <input type="text" name="subkat_en" defaultValue={por_drzava} onChange={(e) => setDrzava(e.target.value)} />

                            <label htmlFor="">Grad</label>
                            <input type="text" name="subkat_en" defaultValue={por_grad} onChange={(e) => setGrad(e.target.value)} />

                            <label htmlFor="">Poštanski broj</label>
                            <input type="text" name="subkat_en" defaultValue={por_postanski_broj} onChange={(e) => setPostanskiBroj(e.target.value)} />
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Način preuzimanja</label>
                            <input type="text" name="subkat_en" defaultValue={por_preuzimanje} onChange={(e) => setPreuzimanje(e.target.value)} />

                            <label htmlFor="">Način placanja</label>
                            <input type="text" name="subkat_en" placeholder={por_placanje} disabled />

                            <label htmlFor="">Vreme kreiranja porudzbine</label>
                            <input type="text" name="subkat_en" placeholder={por_vreme_kreiranja} disabled />

                            {orderStatus(por_status)}

                            <label htmlFor="">Registrovani korisnik</label>

                            {
                                korisnici_korisnik_id == 0 ? (
                                    <input type="text" placeholder='NE' />
                                ) : (
                                    <input type="text" placeholder={korisnici_korisnik_id} />
                                )
                            }
                        </div>
                    </form>
                </div>

                <div className='mt-1 mb-1'>
                    <span className='mb-1'>
                        <label className='accent' htmlFor="">Vrednost porudžbine:</label>
                    </span>

                    <div className='details__price'>
                        <div className='details__products-info p-0'>
                            {orderPrice(price)}
                        </div>
                    </div>
                </div>

                <div className='mt-1 mb-1'>
                    <span className='ordered'>
                        <label className='accent' htmlFor="">Poručeni proizvodi:</label>
                        <span className='order__product-count'><h3>{splitPor.length}</h3></span>
                    </span>

                    <div className='details__products'>
                        <div className='details__products-info'>
                            {
                                splitIme.map((el, idx) => {
                                    return (
                                        <h3 key={idx}>{`${idx + 1}:  ${el}`}</h3>
                                    )
                                })
                            }
                        </div>
                        <div className='details__products-img'>
                            {orderImages(splitSlike)}
                        </div>
                    </div>
                </div>

                <div className='details__options'>
                    <div className='details__options-inner mb-2'>
                        <RiCheckboxCircleLine className='icon-dash-success icon-main mr-1' onClick={updateOrder} />
                        {/* <RiDeleteBinLine className='icon-dash-danger icon-main'
                            onClick={() => deleteOrder(por_id)} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailsEdit