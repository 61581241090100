import React, { useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom';
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage';
import jwt from 'jwt-decode'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"

// import { RequireAuth } from 'react-auth-kit'
import { useAuthUser, useSignOut, useIsAuthenticated } from 'react-auth-kit';
import Search from '../../components/Dashboard/Search/Search';

const Dashboard = () => {


    const auth = useAuthUser()
    const signOut = useSignOut()
    const IsAuthenticated = useIsAuthenticated()
    // console.log(auth())

    document.title = 'Dashboard';
    const navigate = useNavigate();

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

    const [asideMenu, setAsideMenu] = useState(false);
    const [devider, setDevider] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }

        setTimeout(() => {
            dispatch(getDashOrders());
          }, "2000");
        

        setTimeout(() => {
            if (smiliesSession) {
                sessionStorage.removeItem('SmiliesOnlineLog')
                navigate('/')
            }
        }, 1800000);
        // }, 120000);
        
        if(!auth()){
            signOut()
        // //     navigate('/')
        }

    // }, [asideMenu, dispatch, smiliesSession]);
    }, [asideMenu, dispatch, smiliesSession, auth ]);




    // if (smiliesSession) {
    //     const token = jwt(smiliesSession);

        // if (token.data.status == 'Admin' || token.data.status == 'Urednik') {
        if (auth().status == 'Admin' || auth().status == 'Urednik') {
            return (
                <div className='dashboard-container'>
                    <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                    <div className="dashboard__main">
                        <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                        <Search />

                        <div className="main__section">
                            {/*Ovde idu komponente svake stranice. iznad divovi ostaju isti na svakoj stranici*/}


                        </div>
                    </div>
                </div>
            )
        } else {
            return  <ErrorPage notAdmin />
        }
    // } else {
    //     return (
    //         <ErrorPage nologin />
    //     )
    // }

}

export default Dashboard