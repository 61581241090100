const loginFun = ( api, jwt, navigate, sendData, setEmail, setPassword, setIsLoading, notifyError, notifyInfo, notifySuccess, signIn, isAuthenticated, auth ) => {

    // if (!isAuthenticated) {

        api({
            method: 'post',
            url: 'login.php',
            data: sendData,
        })
            .then((response) => {
    
                if (response.data.uspesno) {
    
                    setEmail('');
                    setPassword('');
    
                    const odgovor = response.data;
                    if (odgovor) {

                        const token = jwt(response.data.token);
                        
                        if (token.data.status == 'Admin' || token.data.status == 'Urednik') {

                            sessionStorage.setItem("SmiliesOnlineLog", JSON.stringify({
                                // login:true,
                                token:response.data.token
                            }));

                            signIn({
                                token: response.data.token,
                                expiresIn: 29, 
                                // expiresIn: 1, 
                                tokenType: 'Bearer',
                                authState: {
                                    fname: token.data.name,
                                    lname: token.data.last_name,
                                    status: token.data.status,
                                    email: token.data.email, 
                                    uid: token.data.id
                                }
                            })
    
                            if(isAuthenticated){
    
                                notifySuccess(response.data.uspesno);
                                setIsLoading(false)
                                navigate('/dashboard')
    
                            }else{
                                setIsLoading(false)
                                navigate('/')
                            }

                        } else if (token.data.status === 'Korisnik') {

                            setIsLoading(false)
                            navigate('/adminonly')
                            notifyInfo('Ovo je stranica samo za Administratora!');
                        } else {

                            setIsLoading(false)
                            notifyError("Greška prilikom logovanja");
                        }
    
                    } else {
                        notifyError("Login error.");
                    }
    
                } else if (response.data.greska) {
                    setIsLoading(false)
                    notifyError(response.data.greska);
    
                } else if (response.data.info) {
                    setIsLoading(false)
                    notifyInfo(response.data.info);
                }
            })
    // }else{
    //     navigate('/dashboard')
    //     notifyInfo("Već ste ulogovani");
    //     setIsLoading(false)
    // }
}

export default loginFun