import React from 'react'


const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <span></span>
                {/* <p data-en="Loading" data-sr="Ucitavanje">Loading</p> */}
            </div>
        </div>
    )
}

export default Loader