import authsession from './authsession';

const editProductFun = ( api, data, notifyError, notifyInfo, notifySuccess, dispatch, getProductsDash, setModal, setDetailsWindow ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'productc.php?fun=update',
        data: data,
    })
        .then((response) => {
            if (response.data.uspesno) {

                setDetailsWindow(false)
                setModal(false)
                notifySuccess(response.data.uspesno);
                dispatch(getProductsDash());

            } else if (response.data.greska) {
                notifyError(response.data.greska);
            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default editProductFun