import React, { useState, useEffect } from 'react'
import { RiCloseLine, RiH3 } from 'react-icons/ri'

import Modal from '../../Modal/Modal'
import Loader from '../../Loader/Loader';

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders, editOrder } from "../../../redux/features/orders/ordersSlice"
// import { getProducts, editProduct } from "../../../redux/features/products/productsSlice"
import { getProductsDash, editProduct } from "../../../redux/features/products/productsDashSlice"
import { getUsers, editUsers } from "../../../redux/features/users/usersSlice"

import OrderDetailsView from '../OrderDetailsView/OrderDetailsView';
import OrderDetailsEdit from '../OrderDetailsEdit/OrderDetailsEdit';

import ProductDetailsView from '../ProductDetailsView/ProductDetailsView';
import ProductDetailsEdit from '../ProductDetailsEdit/ProductDetailsEdit';
import UserView from '../UserView/UserView';
import UserEdit from '../UserEdit/UserEdit';

import { useAuthUser } from 'react-auth-kit';


const DetailsWindow = ({ detailsWindow, setDetailsWindow, orderDetailsId, orderOption, setOrderOption, productDetailsId, productOption, setProductOption, userOption, setUserOption, modal, setModal, modalId, setModalId }) => {


    const auth = useAuthUser()
    
    const [detailsId, setDetailsId] = useState('');

    const ordersList = useSelector((state) => state.ordersList)
    const { ordersLoading, ordersData, ordersMessage, dataOrderUpdate } = ordersList;
    const { order } = dataOrderUpdate;


    const productsListDash = useSelector((state) => state.productsListDash)
    const { loading, data, message, dataProductUpdate } = productsListDash;
    const { product } = dataProductUpdate;


    const usersList = useSelector((state) => state.usersList)
    const { usersLoading, usersData, usersMessage, dataUsersUpdate } = usersList;
    const { user } = dataUsersUpdate;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashOrders());
    }, [dispatch]);


    const closeDetailsWindow = () => {
        setDetailsWindow(!detailsWindow);
        setModal(false);
    }


    const openModal = () => {
        setModal(true);
    }


    return (
        <section className={`${detailsWindow ? 'detailsActive' : ''} details__window`} >

            <div className='details__window-details'>
                <RiCloseLine className='icon-main icon-position' onClick={() => closeDetailsWindow()} />

                {/*Switch WindowDetail za opcije naloga*/}
                <article>
                    {
                        order ? (

                            // ordersData.length == 0 ? 
                            // <h3 className='color-danger mt-1 tc-danger-muted'>Prazno</h3> :

                            ordersData.map((ord) => {

                                if (order.por_id === ord.por_id) {

                                    switch (orderOption) {
                                        case 'viewOrder':
                                            return (
                                                <OrderDetailsView key={order.por_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    setOrderOption={setOrderOption}
                                                    modal={modal}
                                                    setModal={setModal}
                                                />
                                            )
                                            break;

                                        case 'editOrder':
                                            return (
                                                <OrderDetailsEdit key={order.por_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    modal={modal}
                                                    setModal={setModal}
                                                />
                                            )
                                            break;

                                            {/*Ovde ostrale opcije koje trebajui da se otvaraju u ProzoruDetalja*/ }
                                        default:
                                            return (
                                                <h2>Nema detalja.</h2>
                                            )
                                            break;
                                    }
                                }
                            })

                        ) : (
                            // <h3 className='color-danger mt-1 tc-danger-muted'>No orders.</h3>
                            ''
                        )
                    }
                </article>

                {/*Switch WindowDetail za opcije Proizvoda*/}
                <article>
                    {
                        product ? (

                            data.map((pro) => {

                                if (product.proizvod_id === pro.proizvod_id) {

                                    switch (productOption) {
                                        case 'viewProduct':
                                            return (
                                                <ProductDetailsView key={pro.proizvod_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    modal={modal}
                                                    setModal={setModal}
                                                    setProductOption={setProductOption}
                                                />
                                            )
                                            break;

                                        case 'editProduct':
                                            return (
                                                <ProductDetailsEdit key={pro.proizvod_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    modal={modal}
                                                    setModal={setModal}
                                                />
                                            )
                                            break;

                                        default:
                                            return (
                                                <h2>Nema detalja.</h2>
                                            )
                                            break;
                                    }
                                }
                            })

                        ) : (
                            // <h3 className='color-danger mt-1 tc-danger-muted'>No product.</h3>
                            ''
                        )
                    }
                </article>

                {/*Switch WindowDetail za opcije Korisnika*/}
                <article>
                    {
                        user ? (

                            usersData.map((us) => {

                                if (user.korisnik_id === us.korisnik_id) {

                                    switch (userOption) {
                                        case 'viewUser':
                                            return (
                                                <UserView key={us.korisnik_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    modal={modal}
                                                    setModal={setModal}
                                                    setUserOption={setUserOption}
                                                />
                                            )
                                            break;

                                        case 'editUser':
                                            return (
                                                <UserEdit key={us.korisnik_id}
                                                    setDetailsWindow={setDetailsWindow}
                                                    detailsWindow={detailsWindow}
                                                    modal={modal}
                                                    setModal={setModal}
                                                />
                                            )
                                            break;

                                        default:
                                            return (
                                                <h2>Nema detalja.</h2>
                                            )
                                            break;
                                    }
                                }
                            })

                        ) : (
                            // <h3 className='color-danger mt-1 tc-danger-muted'>No users.</h3>
                            ''
                        )
                    }
                </article>
            </div>

        </section>
    )


}

export default DetailsWindow