import authsession from './authsession';

const editUsersFun = ( api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getUsers, setModal, setDetailsWindow ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'usersc.php?fun=update&lokacija=dash',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                dispatch(getUsers());
                setModal(false);
                setDetailsWindow(false)
            } else if (response.data.greska) {
                notifyError(response.data.greska);

            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default editUsersFun