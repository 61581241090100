import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri'
import api from '../../../api/api';
import jwt from 'jwt-decode'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';

import { useSelector, useDispatch } from 'react-redux';
import { getUsers, editUsers } from "../../../redux/features/users/usersSlice"
import deleteUserFun from '../../../utils/deleteUserFun';


const UserView = ({ setDetailsWindow, detailsWindow, modal, setModal, setUserOption }) => {

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");
    const user = jwt(smiliesSession);

    const usersList = useSelector((state) => state.usersList)
    const { dataUsersUpdate, dataUsersLoading } = usersList;

    const { korisnik_id, korisnik_email, korisnik_ime, korisnik_prezime,
        korisnik_adresa, korisnik_telefon, korisnik_aktivan, korisnik_obrisan,
        korisnik_status, korisnik_dkreiranja, korisnik_dbrisanja,
        korisnik_verifikovan, korisnik_verifikovan_vreme, pristanak } = dataUsersUpdate.user;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    // User Delete from details window
    const deleteUser = (id, email) => {
        if (window.confirm('Da li ste sigurni da želite da obrišete korisnika?')) {

            const sendData = {
                id: id,
                email: email,
            }

            deleteUserFun(api, sendData, notifyError, notifyInfo, notifySuccess, setModal, setDetailsWindow, dispatch, getUsers)
        }
    }



    return (
        <div className="category__container-inputs order__dash-details">
            <h3 className='accent mb-1'>Detalji registrovanog korisnika</h3>

            <div className='details__container'>

                <div className='details__form'>
                    <form >
                        <div className='form__inputs mr-2'>
                            <label htmlFor="" className='accent'>Status:</label>
                            <input type="text" placeholder={korisnik_status} disabled />

                            <label htmlFor="">Ime:</label>
                            <input type="text" placeholder={korisnik_ime} disabled />

                            <label htmlFor="">Prezime:</label>
                            <input type="text" placeholder={korisnik_prezime} disabled />

                            <label htmlFor="">Email:</label>
                            <input type="text" placeholder={korisnik_email} disabled />

                            <label htmlFor="">Broj telefona:</label>
                            <input type="text" placeholder={korisnik_telefon} disabled />
                        </div>

                        <div className='form__inputs mr-2'>
                            <label htmlFor="">Ulica:</label>
                            <input type="text" placeholder={korisnik_adresa} disabled />

                            <label htmlFor="">Država:</label>
                            <input type="text" placeholder={0} disabled />

                            <label htmlFor="">Grad:</label>
                            <input type="text" placeholder={0} disabled />

                            <label htmlFor="">Poštanski broj:</label>
                            <input type="text" placeholder={0} disabled />
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Vreme registracije korisnika:</label>
                            <input type="text" placeholder={korisnik_dkreiranja} disabled />

                            <label htmlFor="">Vreme brisanja korisnika:</label>
                            {
                                korisnik_dbrisanja == korisnik_dkreiranja ? (<input type="text" placeholder='NE' name="subkat_en" disabled />) : (<input type="text" placeholder={korisnik_dbrisanja} name="subkat_en" disabled />)
                            }

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Akrivan:</label>
                                    {
                                        korisnik_aktivan == 1 ? (<input type="text" placeholder='DA' name="subkat_en" disabled />) : (<input type="text" placeholder='NE' name="subkat_en" disabled />)
                                    }
                                </div>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Obrisan:</label>
                                    {
                                        korisnik_obrisan == 1 ? (<input type="text" placeholder='DA' name="subkat_en" disabled />) : (<input type="text" placeholder='NE' name="subkat_en" disabled />)
                                    }
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">U Korisćenja:</label>
                                    {
                                        pristanak == 1 ? (<input type="text" placeholder='DA' name="subkat_en" disabled />) : (<input type="text" placeholder='NE' name="subkat_en" disabled />)
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className='mt-1 mb-1'>
                    <span className='mb-1'>
                        <label className='accent' htmlFor="">Poručeni proizvodi:</label>
                        <span className='order__product-count ml-1'><h3>{0}</h3></span>
                    </span>

                    <div className='details__products'>
                        <div className='details__products-info'>

                        </div>
                        <div className='details__products-img'>
                        </div>
                    </div>
                </div>

                <div className='details__options'>
                    <div className='details__options-inner mt-3'>
                        <RiEditBoxLine className='icon-dash-info icon-main mr-1' onClick={() => setUserOption('editUser')} />
                        <RiDeleteBinLine className='icon-dash-danger  icon-main'
                            onClick={() => deleteUser(korisnik_id, korisnik_email)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserView