
const signupFun = ( sendData, api, notifyError, notifyInfo, notifySuccess, setFirstName, setLastName, setEmail, setPassword, setRepeatPassword, setTerms, e, setLoginCard, loginCard ) => {

    api({
        method: 'post',
        url: 'signup.php',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                setLoginCard(!loginCard);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setRepeatPassword('');
                setTerms(false);
                e.target.terms.checked = false;

            } else if (response.data.greska) {
                notifyError(response.data.greska);
            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default signupFun