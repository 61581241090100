import React, { useEffect, useState } from 'react'
import api from '../../../api/api';
import { RiDeleteBinLine, RiEditBoxLine, RiSearch2Line, RiEyeLine, RiCloseLine, RiCheckLine } from 'react-icons/ri'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';
import Loader from '../../Loader/Loader';

import { useSelector, useDispatch } from 'react-redux';
import { getProductsDash, editProduct } from "../../../redux/features/products/productsDashSlice"
import Search from '../Search/Search';

import publishProduct from '../../../utils/publishProduct';
import deleteProductFun from '../../../utils/deleteProductFun';

import {useAuthUser } from 'react-auth-kit';


const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;

const SingleProduct = ({ detailsWindow, setDetailsWindow, productOption, setProductOption, setModal, modalId, modal, setModalId }) => {

  const auth = useAuthUser()

  const productsListDash = useSelector((state) => state.productsListDash)
  const { loading, data, message } = productsListDash;



  const updateProduct = (product) => {
    setDetailsWindow(!detailsWindow);
    dispatch(editProduct({
      product
    }, [dispatch]))
  }


  const openProductDetails = (product) => {
    setDetailsWindow(!detailsWindow);
    dispatch(editProduct({
      product
    }, [dispatch]))
  }


  const openModal = () => {
    setModal(!modal);
  }


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductsDash());
  }, [dispatch]);


  // Message je stilizovana komponenta Unutar Toast-a
  const notifyError = (odgovor) => {
    toast.error(<Message error={odgovor} />)
  }
  const notifySuccess = (odgovor) => {
    toast.success(<Message success={odgovor} />);
  }
  const notifyInfo = (odgovor) => {
    toast.info(<Message info={odgovor} />);
  }


  // Product delete
  // Inicijalno state for delete product
  const [productDelete, setproductDelete] = useState({
    id_pr: '',
    ime_pr_sr: '',
    ime_pr_en: '',
  });

  const deleteProduct = (id, ime_sr, ime_en) => {

    if (window.confirm(`Da li ste sigurni da želite da obrišete proizvod '${ime_sr}'?`)) {

      const sendData = {
        id_pr: id,
        ime_pr_sr: ime_sr,
        ime_pr_en: ime_en,
        user: auth().uid
      }

      deleteProductFun(api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getProductsDash, setModal, setDetailsWindow, setproductDelete)
    }
  }


  // Publish product function
  const publishProd = (id, naziv, p) => {

    const sendData = {
      id: id,
      publish: p,
      naziv: naziv, 
      user: auth().uid
    }

    publishProduct(api, notifyError, notifySuccess, notifyInfo, sendData, dispatch, getProductsDash)
  }



  return (

    <div className="products__container">
        {
          loading ? <Loader /> : data.greska ? <h3 className='color-danger'>Nema proizvoda za prikaz.</h3> :

          data.map((product, idx) => {

            let sveSlike = product.slika_ime;
            let splitSlike = sveSlike.split(',');

            return (

                <div className='single__sontainer' key={idx}>

                  <div className="product-header">

                    <div className='product-info'>
                        <div className="product-published">
                          {product.proizvod_objavljen == 1 ? 
                            (<RiCheckLine className='icon-dash-success ico-publish'
                              onClick={(e) => publishProd(product.proizvod_id, product.proizvod_naziv_sr, 0)} />)
                            :
                            (<RiCloseLine className='icon-dash-danger ico-publish'
                              onClick={(e) => publishProd(product.proizvod_id, product.proizvod_naziv_sr, 1)} />)}
                        </div>

                        <p>{product.proizvod_id}</p>
                        <p id='kreirao'>{product.korisnik_ime}</p>
                    </div>

                    <div className="product-options">
                      <RiEyeLine className='icon-dash-success icon-small'
                      onClick={() => [openProductDetails(product), setProductOption('viewProduct'), openModal(), setModalId('productDetails')]} />

                      <RiEditBoxLine className='icon-dash-info icon-small'
                      onClick={() => [updateProduct(product), setProductOption('editProduct'), openModal(), setModalId('productDetails')]} />

                      <RiDeleteBinLine className='icon-dash-danger  icon-small'
                      onClick={() => deleteProduct(product.proizvod_id, product.proizvod_naziv_sr, product.proizvod_naziv_en)} />
                    </div>
                  </div>


                  <div className='product-middle'>
                    <div>
                      <label htmlFor="">Naziv proizvoda:</label>
                      <h3>{product.proizvod_naziv_sr}</h3>
                      <h3>{product.proizvod_naziv_en}</h3>
                    </div>

                    <div>
                      {/* <label htmlFor="">Vreme kreiran:</label>
                      <p>{product.proizvod_vreme_dodat}</p>

                      <label htmlFor="">Vreme izmene:</label>
                      <p>{product.proizvod_vreme_izmene}</p> */}

                        {/* <label htmlFor="">Cena:</label>
                        <p>{product.proizvod_cena}</p> */}
                    </div>

                    <img src={slike + splitSlike[0]} />
                  </div>

                  {/* <div className='product-footer'>
                    <div>
                      <label htmlFor="">Cena:</label>
                      <p>{product.proizvod_cena}</p>
                    </div>

                    <div className="product-published">

                      {product.proizvod_objavljen == 1 ? 
                          (<RiCheckLine className='icon-dash-success ico-publish'
                            onClick={(e) => publishProd(product.proizvod_id, product.proizvod_naziv_sr, 0)} />)
                          :
                          (<RiCloseLine className='icon-dash-danger ico-publish'
                            onClick={(e) => publishProd(product.proizvod_id, product.proizvod_naziv_sr, 1)} />)}
                    </div>
                  </div> */}

                </div>
            )
          })
        }
    </div>
  )
}

export default SingleProduct