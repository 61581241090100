import authsession from './authsession';

const addSubcategory = ( api, sendCreateData, notifyError, notifyInfo, notifySuccess, setSubcategoryEn, setSubcategorySr, setCategory, dispatch, setIsLoading ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'subcategoryc.php?fun=add',
        data: sendCreateData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                setIsLoading(false)
                notifySuccess(response.data.uspesno);
                setSubcategorySr('');
                setSubcategoryEn('');
                setCategory('');
                dispatch(getDashSubCategory());

            } else if (response.data.greska) {
                setIsLoading(false)
                notifyError(response.data.greska);

            } else if (response.data.info) {
                setIsLoading(false)
                notifyInfo(response.data.info);
            }
        })
}

export default addSubcategory