import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';
import jwt from 'jwt-decode'

import api from '../../../api/api';

import { useSelector, useDispatch } from 'react-redux';
import { getDashSubCategory } from "../../../redux/features/subcategoriesdash/subCategoriesDahsSlice";
import { getDashCategories } from "../../../redux/features/categoriesDash/categoriesDashSlice";
import { Navigate } from 'react-router-dom';
import addSubcategory from '../../../utils/addSubcategory';
import Loader from '../../Loader/Loader';
import editSubcategory from '../../../utils/editSubcategory';

import { useAuthUser } from 'react-auth-kit';


const AddSubCategory = () => {

    const auth = useAuthUser()

    const [ isLoading, setIsLoading ] = useState(false);

    // Initial data for create subcategory
    const [subcategorySr, setSubcategorySr] = useState('');
    const [subcategoryEn, setSubcategoryEn] = useState('');
    const [category, setCategory] = useState('');

    // Update subcategories
    const [subkatIdUpdate, setsubkatIdUpdate] = useState('');
    const [subkatSrUpdate, setsubkatSrUpdate] = useState('');
    const [subkatEnUpdate, setsubkatEnUpdate] = useState('');
    const [categoryUpdate, setCategoryUpdate] = useState('');


    const subCategoryList = useSelector((state) => state.subCategoryDashList)
    const { dataUpdate } = subCategoryList;

    const categoryList = useSelector((state) => state.categoryDashList)
    const { loading, data, message } = categoryList;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashCategories());
    }, [dispatch]);


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    // Create subcategory
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)

        const sendCreateData = {
            subcat_sr: subcategorySr,
            subcat_en: subcategoryEn,
            category: category,
            user: auth().uid
        }

        addSubcategory(api, sendCreateData, notifyError, notifyInfo, notifySuccess, setSubcategoryEn, setSubcategorySr, setCategory, dispatch, setIsLoading)
    }


    // Edit Subcategory
    const handleUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true)

        const sendUpdateData = {
            subkat_id: subkatIdUpdate,
            subkat_sr: subkatSrUpdate,
            subkat_en: subkatEnUpdate,
            category: categoryUpdate,
            user: auth().uid
        }

        editSubcategory(api, sendUpdateData,  notifyError, notifyInfo, notifySuccess, setsubkatSrUpdate, setsubkatEnUpdate, setCategoryUpdate, dispatch, setIsLoading)
    }



    // Render Add subcategory form
    const renderAddSubCategory = () => {

        return (
            <form onSubmit={handleSubmit} >
                <label htmlFor="">Ime Podkategorije Srpski</label>
                <input type="text" placeholder='Potkategorija Sr' name="subcat_sr" value={subcategorySr} onChange={(e) => setSubcategorySr(e.target.value)} />

                <label htmlFor="">Ime Podkategorije Engleski</label>
                <input type="text" placeholder='Potkategorija En' name="subcat_en" value={subcategoryEn} onChange={(e) => setSubcategoryEn(e.target.value)} />

                <div className="select-input">
                    <label htmlFor="selectInputs">Kategorija kojoj pripada</label>
                    <select id='selectInputs' name="category" onChange={(e) => setCategory(e.target.value)} >
                        <option value=''>-- Odaberite kategoriju --</option>
                        {
                            data.map((item, idx) => {
                                return (
                                    <option key={idx} value={item.kat_id}>{item.kat_naziv_sr}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="category__container-inputs-content-buttons">
                    
                    {isLoading ?    
                        <button className='btn__dash-regular dash-button-success  mt-2 mr-1 btn-minwidth'> <Loader /> </button> : 
                        <button className='btn__dash-regular dash-button-success  mt-2 mr-1 btn-minwidth'>Dodaj Podkategoriju</button>}
                </div>
            </form>
        )
    }


    // Render Edit subcategory form
    const renderEditSubcategory = () => {
        return (

            <form onSubmit={handleUpdate}>
                <label htmlFor="">Ime Podkategorije Srpski</label>
                <input type="text" placeholder={dataUpdate.subcategory.podkat_naziv_sr} name="subkat_sr" value={subkatSrUpdate} onChange={(e) => setsubkatSrUpdate(e.target.value)} />

                <label htmlFor="">Ime Podkategorije Engleski</label>
                <input type="text" placeholder={dataUpdate.subcategory.podkat_naziv_en} name="subkat_en" value={subkatEnUpdate} onChange={(e) => setsubkatEnUpdate(e.target.value)} />

                <div className="select-input">
                    <label htmlFor="selectInputs">Kategorija kojoj pripada</label>
                    <select id='selectInputs' name="categoryUpdate" onChange={(e) => setCategoryUpdate(e.target.value)} >
                        <option value=''>-- Odaberite kategoriju --</option>
                        {
                            data.map((item, idx) => {
                                return (
                                    <option key={idx} value={item.kat_id}>{item.kat_naziv_sr}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="category__container-inputs-content-buttons">
                    {
                        isLoading ?
                            <button className='btn__dash-regular dash-button-info mt-2 mr-1 btn-minwidth'> <Loader /> </button>
                            :
                            <button className='btn__dash-regular dash-button-info mt-2 mr-1 btn-minwidth' onClick={() => setsubkatIdUpdate(dataUpdate.subcategory.podkat_id)}>Izmeni Podkategoriju</button>
                    }
                    {/* <button className='btn__dash-regular dash-button-danger mt-2' onClick={() => setsubkatIdUpdate('')}> X </button> */}
                </div>
            </form>
        )
    }



    return (
        <div className='category__container container-height'>
            <div className="category__container-header">
                {
                    dataUpdate.subcategory ? <h2>Izmeni Podkategoriju</h2> : <h2>Dodaj Podkategoriju</h2>
                }
            </div>

            <div className="category__container-inputs p-2">
                {
                    dataUpdate.subcategory ? renderEditSubcategory() : renderAddSubCategory()
                }
            </div>
        </div>
    )
}

export default AddSubCategory