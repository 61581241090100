import authsession from './authsession';

const categoryDelete = ( api,sendData, notifySuccess, notifyError, notifyInfo, setBrisanjeKategorije, dispatch, getDashCategories ) => {


    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'categoryc.php?fun=delete',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                setBrisanjeKategorije({ id_kat: '', })
                dispatch(getDashCategories());

            } else if (response.data.greska) {
                notifyError(response.data.greska);

            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default categoryDelete