import React, { useState, useEffect } from 'react'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage';

import SubCategoryLogList from '../../components/Dashboard/LogLists/SubCategoryLogList'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"

import { useAuthUser } from 'react-auth-kit';

const DashSubCategoryLog = () => {

    const auth = useAuthUser();
    document.title = 'Log Subcategory';

    const [asideMenu, setAsideMenu] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);



    if (auth().status == 'Admin') {
        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />

                    <div className="main__section-nosearch">
                        <SubCategoryLogList />


                    </div>
                </div>
            </div>
        )
    } else {
        return  <ErrorPage notAdmin />
    }
}

export default DashSubCategoryLog