import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri'
import api from '../../../api/api';
import jwt from 'jwt-decode'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';

import { useSelector, useDispatch } from 'react-redux';
import { getProductsDash, editProduct } from "../../../redux/features/products/productsDashSlice"
import deleteProductFun from '../../../utils/deleteProductFun';

import { useAuthUser } from 'react-auth-kit';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;
const slikeOdr = process.env.REACT_APP_BACKEND_MAINTENANCE_IMAGES;

const ProductDetailsView = ({ setDetailsWindow, detailsWindow, modal, setModal, setProductOption }) => {

    const auth = useAuthUser()

    const productsListDash = useSelector((state) => state.productsListDash)
    const { dataProductUpdate } = productsListDash;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProductsDash());
    }, [dispatch]);


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }

    
    const { proizvod_id, proizvod_naziv_sr, proizvod_naziv_en,
        proizvod_opis_sr, proizvod_opis_en,
        proizvod_cena, proizvod_velicina,
        proizvod_materijal_sr, proizvod_materijal_en,
        proizvod_boja_sr, proizvod_boja_en,
        proizvod_kolicina, proizvod_tabela_mera,
        podkategorija_podkat_id, korisnik_ime, korisnik_prezime, slika_ime,
        proizvod_cena_snizena, proizvod_vreme_izmene,
        proizvod_vreme_dodat, proizvod_pogledan,
        proizvod_ocena, podkat_naziv_sr, podkat_naziv_en, odrzavanje_slike } = dataProductUpdate.product;


    const splitSlike = slika_ime.split(',');
    const splitSlikeOdr = odrzavanje_slike.split(',');


    const deleteProduct = (id, ime_sr, ime_en) => {

        if (window.confirm(`Da li ste sigurni da želite da obrišete proizvod '${ime_sr}'?`)) {

            const sendData = {
                id_pr: id,
                ime_pr_sr: ime_sr,
                ime_pr_en: ime_en,
                user: auth().uid
            }

            deleteProductFun(api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getProductsDash, setModal, setDetailsWindow)
        }
    }



    return (
        <div className="category__container-inputs order__dash-details">
            <h3 className='accent'>Detalji modela</h3>

            <div className='details__container'>

                <div className='details__form'>
                    <form >
                        <div className='form__inputs'>
                            <label htmlFor="">Naziv proizvoda Sr:</label>
                            <input type="text" placeholder={proizvod_naziv_sr} name="subkat_sr" disabled />

                            <label htmlFor="">Naziv proizvoda Er:</label>
                            <input type="text" placeholder={proizvod_naziv_en} name="subkat_en" disabled />

                            <label htmlFor="">Materijal Sr:</label>
                            <input type="text" placeholder={proizvod_materijal_sr} name="subkat_en" disabled />

                            <label htmlFor="">Materijal Er:</label>
                            <input type="text" placeholder={proizvod_materijal_en} name="subkat_en" disabled />

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Veličina:</label>
                                    <input type="text" placeholder={proizvod_velicina} name="subkat_en" disabled />
                                </div>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Količina:</label>
                                    <input type="text" placeholder={proizvod_kolicina} name="subkat_en" disabled />
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">TM:</label>
                                    <input type="text" placeholder={proizvod_tabela_mera} name="subkat_en" disabled />
                                </div>
                            </div>

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label className='color-info-muted' htmlFor="">Podkategorija Sr:</label>
                                    <input type="text" placeholder={podkat_naziv_sr} disabled />
                                </div>
                                <div className='form__inputs'>
                                    <label className='color-info-muted' htmlFor="">Podkategorija En:</label>
                                    <input type="text" placeholder={podkat_naziv_en} disabled />
                                </div>
                            </div>

                            <div className='form__inputs mt-2'>
                                <label className='color-success-muted' htmlFor="">Regularna Cena:</label>
                                <input type="text" placeholder={proizvod_cena} disabled />
                            </div>
                            <div className='form__inputs'>
                                <label className='color-danger-muted' htmlFor="">Snizena Cena:</label>
                                <input type="text" placeholder={proizvod_cena_snizena} disabled />
                            </div>
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Boja Sr:</label>
                            <input type="text" placeholder={proizvod_boja_sr} name="subkat_en" disabled />

                            <label htmlFor="">Boja Er:</label>
                            <input type="text" placeholder={proizvod_boja_en} name="subkat_en" disabled />


                            <label htmlFor="">Opis proizvoda Sr</label>
                            <textarea className='p-1 mb-1' name="opis_sr" id="" cols="30" rows="6" placeholder={proizvod_opis_sr} disabled></textarea>

                            <label htmlFor="">Opis proizvoda En</label>
                            <textarea className='p-1' name="opis_en" id="" cols="30" rows="6" placeholder={proizvod_opis_en} disabled></textarea>
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Vreme kreiranja:</label>
                            <input type="text" placeholder={proizvod_vreme_dodat} name="subkat_en" disabled />

                            <label htmlFor="">Vreme izmene:</label>
                            <input type="text" placeholder={proizvod_vreme_izmene} name="subkat_en" disabled />

                            <label htmlFor="">Kreirao korisnik:</label>
                            <input type="text" placeholder={`${korisnik_ime} ${korisnik_prezime}`} disabled />

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Pogledan puta:</label>
                                    <input type="text" placeholder={proizvod_pogledan} name="subkat_en" disabled />
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">Ocena proizvoda:</label>
                                    <input type="text" placeholder={proizvod_ocena} name="subkat_en" disabled />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className='mt-1 mb-1'>
                    <label className='accent' htmlFor="">Slike proizvoda:</label>

                    <div className='details__products'>
                        <div className='details__products-img'>
                            {
                                splitSlike.map((img, idx) => {
                                    return (
                                        <div key={idx} className='details__products-img-content'>
                                            <img src={slike + img} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='mt-1 mb-1'>
                    <label className='accent' htmlFor="">Slike održavanje:</label>

                    <div className='details__products'>
                        <div className='details__products-img'>
                            {
                                splitSlikeOdr.map((img, idx) => {
                                    return (
                                        <div key={idx} className='details__products-img-content'>
                                            <img src={slikeOdr + img} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='details__options'>
                    <div className='details__options-inner mb-1'>
                        <RiEditBoxLine className='icon-dash-info icon-main mr-1' onClick={() => setProductOption('editProduct')} />
                        <RiDeleteBinLine className='icon-dash-danger  icon-main'
                            onClick={() => deleteProduct(proizvod_id, proizvod_naziv_sr, proizvod_naziv_en)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsView