import authsession from './authsession';


const orderEdit = ( api, sendData, notifySuccess, notifyError, notifyInfo, dispatch, getDashOrders, setDetailsWindow, setModal ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'orderc.php?fun=update',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                dispatch(getDashOrders());
                notifySuccess(response.data.uspesno);
                setModal(false)
                setDetailsWindow(false)
            } else if (response.data.greska) {
                notifyError(response.data.greska);
                
            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default orderEdit