import authsession from './authsession';

const publishProduct = ( api, notifyError, notifySuccess, notifyInfo, sendData, dispatch, getProductsDash ) =>{


    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'productc.php?fun=publish',
        data: sendData,
        })
        .then((response) => {

            if (response.data.uspesno) {
                notifySuccess(response.data.uspesno);
                dispatch(getProductsDash());

            } else if (response.data.greska) {
                notifyError(response.data.greska);

            } else if (response.data.info) {
                notifyInfo(response.data.info);
                dispatch(getProductsDash());
            }
        });
}

export default publishProduct