import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoginCard from '../../components/LoginCard/LoginCard';
import SigninCard from '../../components/SigninCard/SigninCard';
import logo from '../../assets/img/logos/Smilies-Black-2022.svg';


const LoginSignin = () => {

    // let curentLocation = useLocation();
    // useEffect(() => {
    //     setLocation(curentLocation.pathname);
    // }, []);

    const [loginCard, setLoginCard] = useState(false);
    const [ isLoading, setIsLoading] = useState(false);

    const cardRotation = () => {
        setLoginCard(!loginCard);
    };

    return (

        <div className="container">
            <img src={logo} alt="Smilies Logo" />
            <h2>Admin area</h2>

            <div className="login-container">
                <LoginCard 
                    loginCard={loginCard} 
                    setLoginCard={setLoginCard} 
                    isLoading={isLoading}
                    setIsLoading={setIsLoading} 
                 />

                <SigninCard 
                    loginCard={loginCard} 
                    setLoginCard={setLoginCard} 
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </div>
        </div>
    )
}

export default LoginSignin