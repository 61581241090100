import React from 'react';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import Button from '../Button/Button'
import api from '../../api/api';
import jwt from 'jwt-decode'


// Styling
import { IoPersonOutline, IoPersonAddOutline, IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import 'react-toastify/dist/ReactToastify.css';
import Message from '../Message/Message';
import Loader from '../Loader/Loader';
import loginFun from '../../utils/loginFun';

import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';



const LoginCard = ({ loginCard, setLoginCard, isLoading, setIsLoading }) => {

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()
    const auth = useAuthUser()

    const [showLoginPass, setShowLoginPass] = useState('password');
    const [login, setLogin] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const cardRotation = () => {
        setLoginCard(!loginCard);
    };

    const handleShowPass = () => {
        showLoginPass === 'password' ? setShowLoginPass('text') : setShowLoginPass('password')
    }



    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    const submitLogin = (e) => {
        e.preventDefault();

        setIsLoading(true)

        const sendData = {
            email: email,
            password: password,
            // remember: data.terms
        }

        loginFun(api, jwt, navigate, sendData, setEmail, setPassword, setIsLoading, notifyError, notifyInfo, notifySuccess, signIn, isAuthenticated, auth)
    }


    return (

        <div className={`${loginCard ? 'loginActive' : ''} login-card loginCard `} id="">

            <div className="login-card-header">
                <span><IoPersonOutline className='icon-xl' /></span>
                <h1 data-en='Login' data-sr='Prijavljivanje'>Prijavljivanje</h1>
            </div>

            <form autoComplete="off" onSubmit={submitLogin}>

                <div className="form-imputs">
                    <div className="inputs">
                        <input type="email" autoFocus  placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="inputs">
                        <input type={showLoginPass === 'text' ? 'text' : 'password'} name="password" placeholder="Lozinka" value={password} onChange={(e) => setPassword(e.target.value)} />

                        { showLoginPass === 'text' ? 
                        <IoEyeOutline className='visible__pass' onClick={handleShowPass} /> :  
                        <IoEyeOffOutline className='visible__pass' onClick={handleShowPass} /> }

                    </div>
                    
                    {/* <div className="pass__reset">
                        <span onClick={resetPass} data-en='Forgotten password?' data-sr='Zaboravili ste lozinku?'>Zaboravili ste lozinku?</span>
                    </div> */}
                </div>

                <div className="inputs">
                    {/*<button type='submit' className="btn" onClick={handleClick} data-en='Login' data-sr='Prijava'>Prijava</button>*/}

                    {isLoading ?    
                        <button className="btn" data-en='Login' data-sr='Prijava'><Loader /> </button>
                    :   
                        <button className="btn" data-en='Login' data-sr='Prijava'>Prijava</button> }
                </div>
            </form>

            {/* <div className="switch">
                <span onClick={cardRotation} data-en='Go to Signup' data-sr='Idi na registraciju'>Idi na registraciju</span>
            </div> */}
        </div>

    )
}

export default LoginCard 