import React, { useState, useEffect } from 'react'
import AddSubCategory from '../../components/Dashboard/AddSubCategory/AddSubCategory'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage'
import SubCategoryList from '../../components/Dashboard/SubCategoryList/SubCategoryList'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"

import { useAuthUser } from 'react-auth-kit';
import Search from '../../components/Dashboard/Search/Search'

const DashSubCategories = () => {

    
    const auth = useAuthUser();
    document.title = 'Dash SubCategories';

    const [asideMenu, setAsideMenu] = useState(false);
    const [devider, setDevider] = useState(false);

    const dispatch = useDispatch();

    
    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);


    if (auth().status == 'Admin' || auth().status == 'Urednik') {

        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                    <Search />

                    <div className="main__section category__layout-grid">

                        <AddSubCategory />
                        <SubCategoryList />
                    </div>
                </div>
            </div>
        )
    } else {
        return <ErrorPage notAdmin />   
    }
}

export default DashSubCategories