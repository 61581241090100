import react, {useState} from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider, RequireAuth } from 'react-auth-kit'

import Login from './pages/Login/Login'

// Dashboard pages
import Dashboard from './pages/Dashboard/Dashboard';
import DashCategories from './pages/Dashboard/DashCategories';
import DashSubCategories from './pages/Dashboard/DashSubCategories';
import DashCreateProduct from './pages/Dashboard/DashCreateProduct';
import DashProductList from './pages/Dashboard/DashProductList';

import DashLogedUsersLog from './pages/Dashboard/DashLogedUsersLog';
import DashRegisteredUsersLog from './pages/Dashboard/DashRegisteredUsersLog.js';
import DashCategoryLog from './pages/Dashboard/DashCategoryLog';
import DashSubCategoryLog from './pages/Dashboard/DashSubCategoryLog';
import DashProductLog from './pages/Dashboard/DashProductLog';

// import DashProductLog from './pages/Dashboard/DashProductLog';

import DashOrdersLog from './pages/Dashboard/DashOrdersLog';
import DashUsers from './pages/Dashboard/DashUsers';
import Orders from './pages/Dashboard/Orders';
import ErrorPage from './pages/ErrorPage/ErrorPage';


const App = () => {

  const [location, setLocation] = useState('/');

  return (

    <AuthProvider
      authType = {'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      // cookieSecure={window.location.protocol === "https:"}>
      cookieSecure={false}
    >

      <Router>
        <ToastContainer autoClose={2500} />

        <Routes>
            <Route path='/' element={ <Login /> } />

            <Route path='/dashboard' element={ <RequireAuth loginPath='/nologin' ><Dashboard  /></RequireAuth> } />
            <Route path='/orders' element={ <RequireAuth loginPath='/nologin' ><Orders  /></RequireAuth> } />
            <Route path='/dash-categories'  element={ <RequireAuth loginPath='/nologin' ><DashCategories  /></RequireAuth> } />
            <Route path='/dash-subcategories' element={ <RequireAuth loginPath='/nologin' ><DashSubCategories  /></RequireAuth> } />
            <Route path='/dash-create-product' element={ <RequireAuth loginPath='/nologin' ><DashCreateProduct  /></RequireAuth> } />
            <Route path='/dash-product-list' element={ <RequireAuth loginPath='/nologin' ><DashProductList  /></RequireAuth> } />
            <Route path='/dash-users' element={ <RequireAuth loginPath='/nologin' ><DashUsers  /></RequireAuth> } />
            <Route path='/DashLogedUsersLog' element={ <RequireAuth loginPath='/nologin' ><DashLogedUsersLog  /></RequireAuth> } />
            <Route path='/dashcategoryLog' element={ <RequireAuth loginPath='/nologin' ><DashCategoryLog  /></RequireAuth> } />
            <Route path='/DashRegisteredUsersLog' element={ <RequireAuth loginPath='/nologin' ><DashRegisteredUsersLog /></RequireAuth> } />
            <Route path='/dashsubcategoryLog' element={ <RequireAuth loginPath='/nologin' ><DashSubCategoryLog /></RequireAuth> } />
            <Route path='/dashOrdersLog' element={ <RequireAuth loginPath='/nologin' ><DashOrdersLog /></RequireAuth> } />
            <Route path='/dashProductLog' element={ <RequireAuth loginPath='/nologin' ><DashProductLog /></RequireAuth> } />

            {/* <Route path='/dashproductLog' element={<DashProductLog location={location} setLocation={setLocation} />} /> */}

            <Route path='/nologin' element={<ErrorPage nologin />} />
            <Route path='/adminonly' element={<ErrorPage notAdmin />} />

            <Route path='*' element={<ErrorPage error />} />
        </Routes>
      </Router>

    </AuthProvider>
  );
}

export default App;
