import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiEditBoxLine, RiSearch2Line, RiEyeLine, RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';
import Loader from '../../Loader/Loader';
import jwt from 'jwt-decode'

import api from '../../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, editUsers } from "../../../redux/features/users/usersSlice"
import deleteUserFun from '../../../utils/deleteUserFun';


const UserList = ({ detailsWindow, setDetailsWindow, userOption, setUserOption, modal, setModal, modalId, setModalId }) => {

    const usersList = useSelector((state) => state.usersList)
    const { usersLoading, usersData, usersMessage } = usersList;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);


    // Modal funxction
    const openModal = () => {
        setModal(true);
    }


    // User details window
    const openUserDetails = (user) => {
        setDetailsWindow(!detailsWindow);
        setUserOption('viewUser')
        openModal()
        setModalId('userDetails')

        dispatch(editUsers({
            user
        }, [dispatch]))
    }


    // Edit users
    const editUser = (user) => {
        setDetailsWindow(!detailsWindow);
        setUserOption('editUser')
        openModal()
        setModalId('userDetails')

        dispatch(editUsers({
            user
        }, [dispatch]))
    }


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    // Delete user
    const deleteUser = (id, email) => {

        if (window.confirm('Da li ste sigurni da želite da obrišete korisnika?')) {

            const sendData = {
                id: id,
                email: email,
            }

            deleteUserFun(api, sendData, notifyError, notifyInfo, notifySuccess, setModal, setDetailsWindow, dispatch, getUsers)
        }
    }



    return (

        <div className='category__container category-list'>
            <div className="category__container-header">
                <h2>Lista regirstrovanih korisnika</h2>
            </div>

            <div className="category__container-inputs">

                <div className="table mt-1">
                    <table >
                        <thead>
                            <tr>
                                <th className='column-x-small'>Id</th>
                                <th className='column-medium'>Status</th>
                                <th className='column-xxl'>Korisnik</th>
                                <th className='column-xxl'>Email</th>
                                <th className='column-small options'>Aktivan</th>
                                <th className='column-small options'>Obrisan</th>
                                <th className='column-medium options'>Verifkovan</th>
                                <th className='column-small options'>Pristanak</th>
                                <th className='column-medium options'>Opcije</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                usersLoading ? <tr><td> <Loader /> </td></tr> : usersData.greska ? <h3 className='color-danger'>{usersData.greska}</h3> :

                                    usersData.map((user, idx) => {

                                        return (
                                            <tr key={idx}>
                                                <td className='column-x-small'>{user.korisnik_id}</td>
                                                <td className='column-medium'>{user.korisnik_status}</td>
                                                <td className='column-xxl'>{user.korisnik_ime} {user.korisnik_prezime}</td>
                                                <td className='column-xxl'>{user.korisnik_email}</td>
                                                <td className='column-small options'>{user.korisnik_aktivan == 1 ? (<RiCheckLine className='tc-success-muted  icon-small' />) : (<RiCloseLine className='tc-danger-muted  icon-small'/>)}</td>
                                                {
                                                    user.korisnik_obrisan == 1 ? (<td className='column-small tc-danger-muted options'><RiCheckLine className='tc-success-muted  icon-small' /></td>) : (<td className='column-small options'><RiCloseLine className='tc-danger-muted  icon-small'/></td>)
                                                }
                                                {
                                                    user.korisnik_verifikovan == 1 ? (<td className='column-small tc-danger-muted options'><RiCheckLine className='tc-success-muted  icon-small' /></td>) : (<td className='column-small options'><RiCloseLine className='tc-danger-muted  icon-small'/></td>)
                                                }
                                                <td className='column-small options'>{user.pristanak == 1 ? (<RiCheckLine className='tc-success-muted  icon-small' />) : (<RiCloseLine className='tc-danger-muted  icon-small'/>)}</td>

                                                <td className='column-medium options'>
                                                    <RiEyeLine className='icon-dash-success icon-small'
                                                        onClick={() => openUserDetails(user)} />

                                                    <RiEditBoxLine className='icon-dash-info icon-small'
                                                        onClick={() => editUser(user)} />

                                                    <RiDeleteBinLine className='icon-dash-danger  icon-small'
                                                        onClick={() => deleteUser(user.korisnik_id, user.korisnik_email)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UserList