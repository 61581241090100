import authsession from './authsession';

const logUsersRegistraton = ( api, data, setLogData, notifyError, notifyInfoz ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'logsc.php?fun=read&lokacija=registracija',
        data: data,
    })
        .then((response) => {

            if (response.data.uspesno) {
                setLogData(response.data);
            } else if (response.data.greska) {
                notifyError(response.data.greska);
            } else if (response.data.info) {
                notifyInfo(response.data.info);
            }
        })
}

export default logUsersRegistraton