import React, { useState } from 'react'
import { useEffect } from 'react';
import {useAuthUser, useIsAuthenticated} from 'react-auth-kit';

import {
    RiArrowDownSLine, RiFileList2Line, RiUserLine, RiSunLine, RiMoonLine, RiAddLine, RiDeleteBinLine,
    RiEditBoxLine, RiTShirt2Line, RiUserAddLine, RiUserFollowLine, RiUserUnfollowLine, RiUserSettingsLine,
    RiLineChartLine, RiLoginCircleLine, FcLowPriority, RiShoppingCartLine, RiFolderSettingsLine, RiApps2Line, 
    RiAppsLine, RiDashboardLine, RiCloseLine
} from 'react-icons/ri'

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../../../assets/img/logos/Smilies-White-2022.svg'


const MobileMenu = ({ mobileMenu, setMobileMenu }) => {

    const auth = useAuthUser()

    const ordersList = useSelector((state) => state.ordersList)
    const { ordersLoading, ordersData, ordersMessage } = ordersList;


    const [devider, setDevider] = useState(false);
    const [groupProducts, setGroupProducts] = useState(false);
    const [groupUsers, setGroupUsers] = useState(false);
    const [groupLog, setGroupLog] = useState(false);


    const  closeMobileMenu = () => {
        setMobileMenu(false)
    }


  return (
    <section className={`${mobileMenu ? 'mobileActive' : ''} mobile__container`}>
        
        <RiCloseLine className='closeMobileMenu' onClick={closeMobileMenu}/>

        <Link to="/dashboard" >
            <div className="mobile-menu-top" onClick={closeMobileMenu}>
                    <img src={logo} alt="logo" />
            </div>
        </Link>

        <div className="aside__menu-bottom-content group-hover" onClick={closeMobileMenu}>
            <div className="group">
                <div className='group-header' >
                    <Link to="/orders" className='link-style-icon'><RiShoppingCartLine className="icon-main icon-dash-green ml-18 " /></Link>
                    <span className='link-style' ><Link to="/orders" className='link-style'>Porudzbine</Link></span>
                    {
                        ordersData.greska ? (
                            <span className='order__product-count '><h3>0</h3></span>
                        ) : (
                            <span className='order__product-count '><h3>{ordersData.length}</h3></span>
                        )
                    }
                </div>
            </div>
        </div>

        {/* <div className={`${devider ? 'closedDevider' : ''} devider__aside`}></div> */}

        <div className="aside__menu-bottom-content group-hover">
            <div className="group">
                <div className='group-header' onClick={closeMobileMenu}>
                    <Link to="/dashboard" className='link-style-icon'><RiDashboardLine className="icon-main ml-18" /></Link>
                    <span className='link-style'><Link to="/dashboard" className='link-style'>Dashboard</Link></span>
                </div>
            </div>
        </div>

        
        <div className="aside__menu-bottom-content group-hover">
            <div className="group">
                <div className='group-header' onClick={closeMobileMenu}>
                    <Link to="/dash-categories" className='link-style-icon'><RiApps2Line className="icon-main ml-18" /></Link>
                    <span className='link-style'><Link to="/dash-categories" className='link-style'>Kategorije</Link></span>
                </div>
            </div>
        </div>

        <div className="aside__menu-bottom-content group-hover">
            <div className="group">
                <div className='group-header' onClick={closeMobileMenu}>
                    <Link to="/dash-subcategories" className='link-style-icon'><RiAppsLine className="icon-main ml-18" /></Link>
                    <span className='link-style'><Link to="/dash-subcategories" className='link-style'>Podkategorije</Link></span>
                </div>
            </div>
        </div>


        <div className="aside__menu-bottom-content group-hover">
            <div className={`${groupProducts ? 'toggleHeader' : ''} group `}>
                <div className='group-header' onClick={() => [setGroupProducts(!groupProducts), setGroupLog(false), setGroupUsers(false)]} >
                    <RiTShirt2Line className="icon-main ml-18" />
                    <p ><RiArrowDownSLine className="icon-main icon-dash-submenu moveIcon" />Proizvodi</p>
                </div>
                <div className="group-content" >
                    <ul>
                        <div className='group-header' onClick={closeMobileMenu}>
                            <Link to="/dash-create-product" className='link-style-icon'><RiAddLine className="icon-main" /></Link>
                            <span className='link-style'><Link to="/dash-create-product" className='link-style-group'>Dodaj proizvod</Link></span>
                        </div>
                        <div className='group-header' onClick={closeMobileMenu}>
                            <Link to="/dash-product-list" className='link-style-icon'><RiFileList2Line className="icon-main" /></Link>
                            <span className='link-style'><Link to="/dash-product-list" className='link-style-group'>Lista proizvoda</Link></span>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        
        <div className="aside__menu-bottom-content group-hover">
            <div className="group">
                <div className='group-header' onClick={closeMobileMenu}>
                    <RiLineChartLine className="icon-main ml-18" />
                    <p >Statistika</p>
                </div>
            </div>
        </div>

        {
            auth().status == 'Admin' ? (
                <div className="aside__menu-bottom-content group-hover">
                    <div className="group">
                        <div className='group-header' onClick={closeMobileMenu}>
                            <Link to="/dash-users" className='link-style-icon'><RiUserLine className="icon-main ml-18" /></Link>
                            <span className='link-style'><Link to="/dash-users" className='link-style'>Korisnici</Link></span>
                        </div>
                    </div>
                </div>
            ) : ('')
        }

        {
            auth().status == 'Admin' ? (

                <div className="aside__menu-bottom-content group-hover">
                    <div className={`${groupLog ? 'toggleHeader-xl' : ''} group `}>
                        <div className='group-header' onClick={() => [setGroupLog(!groupLog), setGroupProducts(false), setGroupUsers(false)]}>
                            <RiLoginCircleLine className="icon-main ml-18" />
                            <p ><RiArrowDownSLine className="icon-main icon-dash-submenu moveIcon" />Log</p>
                        </div>
                        <div className="group-content">
                            <div className='group-header' >
                                <Link to="/dashCategoryLog" className='link-style-icon'><RiApps2Line className="icon-main" /></Link>
                                <span className='link-style'><Link to="/dashCategoryLog" className='link-style-group'>Log kategorije</Link></span>
                            </div>
                            <div className='group-header'>
                                <Link to="/dashSubcategoryLog" className='link-style-icon'><RiAppsLine className="icon-main" /></Link>
                                <span className='link-style'><Link to="/dashSubcategoryLog" className='link-style-group'>Log podkategorije</Link></span>
                            </div>
                            <div className='group-header'>
                                <Link to="/dashProductLog" className='link-style-icon'><RiTShirt2Line className="icon-main" /></Link>
                                <span className='link-style'><Link to="/dashProductLog" className='link-style-group'>Log proizvodi</Link></span>
                            </div>
                            <div className='group-header'>
                                <Link to="/dashOrdersLog" className='link-style-icon'><RiShoppingCartLine className="icon-main" /></Link>
                                <span className='link-style'><Link to="/dashOrdersLog" className='link-style-group'>Log porudzbine</Link></span>
                            </div>
                            <div className='group-header'>
                                <Link to="/dashLogedUsersLog" className='link-style-icon'><RiUserFollowLine className="icon-main" /></Link>
                                <span className='link-style'><Link to="/dashLogedUsersLog" className='link-style-group'>Log Ulogovani</Link></span>
                            </div>
                            <div className='group-header'>
                                <Link to="/DashRegisteredUsersLog" className='link-style-icon'><RiUserAddLine className="icon-main" /></Link>
                                <span className='link-style'><Link to="/DashRegisteredUsersLog" className='link-style-group'>Log Registracija</Link></span>
                            </div>
                        </div>
                    </div>
                </div>

            ) : ('')
        }
        
    </section>
  )
}

export default MobileMenu