import authsession from './authsession';

const editSubcategory = ( api, sendUpdateData,  notifyError, notifyInfo, notifySuccess, setsubkatSrUpdate, setsubkatEnUpdate, setCategoryUpdate, dispatch, setIsLoading ) => {

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: 'subcategoryc.php?fun=update',
        data: sendUpdateData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                
                setIsLoading(false)
                notifySuccess(response.data.uspesno);
                setsubkatSrUpdate('');
                setsubkatEnUpdate('');
                setCategoryUpdate('');
                dispatch(getDashSubCategory());

            } else if (response.data.greska) {

                setIsLoading(false)
                notifyError(response.data.greska);
            } else if (response.data.info) {

                setIsLoading(false)
                notifyInfo(response.data.info);
            }
        })
}

export default editSubcategory