import { configureStore } from '@reduxjs/toolkit';

import categoryDashSliceReducer from './redux/features/categoriesDash/categoriesDashSlice';
import subCategoryDashSliceReducer from './redux/features/subcategoriesdash/subCategoriesDahsSlice';

// import productsSlice from './redux/features/products/productsSlice';
import productsDashSlice from './redux/features/products/productsDashSlice';

import ordersSlice from './redux/features/orders/ordersSlice';

import usersSlice from './redux/features/users/usersSlice';

export const store = configureStore({
    reducer: {

        categoryDashList: categoryDashSliceReducer,
        subCategoryDashList: subCategoryDashSliceReducer,

        // productsList: productsSlice,
        productsListDash: productsDashSlice,

        ordersList: ordersSlice,

        usersList: usersSlice,
    }
})

export default store;