const authsession = () => {

    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

    var obj = JSON.parse(smiliesSession)
    var token
    for(var tok in obj) {
        token = obj[tok];
    }

    return token
}

export default authsession