import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiCheckboxCircleLine } from 'react-icons/ri'
import api from '../../../api/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';

import { useSelector, useDispatch } from 'react-redux';
import { getProductsDash, editProduct } from "../../../redux/features/products/productsDashSlice"
import { getDashSubCategory } from "../../../redux/features/subcategoriesdash/subCategoriesDahsSlice";
import deleteProductFun from '../../../utils/deleteProductFun';
import editProductFun from '../../../utils/editProductFun';

import { useAuthUser } from 'react-auth-kit';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;
const slikeOdr = process.env.REACT_APP_BACKEND_MAINTENANCE_IMAGES;

const ProductDetailsEdit = ({ setDetailsWindow, detailsWindow, modal, setModal }) => {


    const auth = useAuthUser()

    // Products state
    const productsListDash = useSelector((state) => state.productsListDash)
    const { dataProductUpdate } = productsListDash;

    // subcategory state
    const subCategoryList = useSelector((state) => state.subCategoryDashList)
    const { subLoading, subData, subMessage } = subCategoryList;


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProductsDash());
        dispatch(getDashSubCategory());
    }, [dispatch]);

    const { proizvod_id, proizvod_naziv_sr, proizvod_naziv_en,
        proizvod_opis_sr, proizvod_opis_en,
        proizvod_cena, proizvod_velicina,
        proizvod_materijal_sr, proizvod_materijal_en,
        proizvod_boja_sr, proizvod_boja_en,
        proizvod_kolicina, proizvod_tabela_mera,
        podkategorija_podkat_id, korisnik_ime, korisnik_prezime, slika_ime,
        proizvod_cena_snizena, proizvod_vreme_izmene,
        proizvod_vreme_dodat, proizvod_pogledan,
        proizvod_ocena, podkat_naziv_sr, podkat_naziv_en, odrzavanje_slike } = dataProductUpdate.product;


    const splitSlike = slika_ime.split(',');
    const splitSlikeOdr = odrzavanje_slike.split(',');


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    // Edit product initial state
    const [imeSr, setImeSr] = useState(proizvod_naziv_sr);
    const [imeEn, setImeEn] = useState(proizvod_naziv_en);
    const [podkatpr, setPodkatpr] = useState(podkategorija_podkat_id);
    const [cenapr, setCenapr] = useState(proizvod_cena);
    const [snizenaCena, setSnizenaCena] = useState(proizvod_cena_snizena);
    const [opisSr, setOpisSr] = useState(proizvod_opis_sr);
    const [opisEn, setOpisEn] = useState(proizvod_opis_en);
    const [velicina, setVelicina] = useState(proizvod_velicina);
    const [materijalSr, setMaterijalSr] = useState(proizvod_materijal_sr);
    const [materijalEn, setMaterijalEn] = useState(proizvod_materijal_en);
    const [kolicina, setKolicina] = useState(proizvod_kolicina);
    const [bojaSr, setBojaSr] = useState(proizvod_boja_sr);
    const [bojaEn, setBojaEn] = useState(proizvod_boja_en);
    const [tabela, setTabela] = useState(proizvod_tabela_mera);



    const deleteProduct = (id, ime_sr, ime_en) => {

        if (window.confirm(`Da li ste sigurni da želite da obrišete proizvod '${ime_sr}'?`)) {

            const sendData = {
                id_pr: id,
                ime_pr_sr: ime_sr,
                ime_pr_en: ime_en,
                user: auth().uid
            }

            deleteProductFun(api, sendData, notifyError, notifyInfo, notifySuccess, dispatch, getProductsDash, setModal, setDetailsWindow)
        }
    }


    const updateProduct = () => {

        const data = {
            imeSr: imeSr,
            imeEn: imeEn,
            podkatpr: podkatpr,
            cenapr: cenapr,
            snizenaCena: snizenaCena,
            opisSr: opisSr,
            opisEn: opisEn,
            velicina: velicina,
            materijalSr: materijalSr,
            materijalEn: materijalEn,
            kolicina: kolicina,
            bojaSr: bojaSr,
            bojaEn: bojaEn,
            tabela: tabela,
            user: auth().uid,
            productId: proizvod_id
        }

        editProductFun(api, data, notifyError, notifyInfo, notifySuccess, dispatch, getProductsDash, setModal, setDetailsWindow)
    }



    return (
        <div className="category__container-inputs order__dash-details">
            <h3 className='accent'>Izmena modela</h3>

            <div className='details__container'>

                <div className='details__form'>
                    <form>
                        <div className='form__inputs'>
                            <label htmlFor="">Naziv proizvoda Sr:</label>
                            <input type="text" defaultValue={proizvod_naziv_sr} name="subkat_sr" onChange={(e) => setImeSr(e.target.value)} />

                            <label htmlFor="">Naziv proizvoda Er:</label>
                            <input type="text" defaultValue={proizvod_naziv_en} name="subkat_en" onChange={(e) => setImeEn(e.target.value)} />

                            <label htmlFor="">Materijal Sr:</label>
                            <input type="text" defaultValue={proizvod_materijal_sr} name="subkat_en" onChange={(e) => setMaterijalSr(e.target.value)} />

                            <label htmlFor="">Materijal Er:</label>
                            <input type="text" defaultValue={proizvod_materijal_en} name="subkat_en" onChange={(e) => setMaterijalEn(e.target.value)} />


                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Veličina:</label>
                                    <select id='selectInputs' name="velicina" defaultValue={proizvod_velicina} onChange={(e) => setVelicina(e.target.value)} >
                                        <option value="36">36</option>
                                        <option value="38">38</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="44">44</option>
                                        <option value="50">50</option>
                                        <option value="52">52</option>
                                    </select>
                                </div>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Količina:</label>
                                    <select id='selectInputs' name="kolicina" defaultValue={proizvod_kolicina} onChange={(e) => setKolicina(e.target.value)} >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select>
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">TM:</label>
                                    <select id='selectInputs' name="tabela" defaultValue={proizvod_tabela_mera} onChange={(e) => setTabela(e.target.value)} >
                                        <option value="z">zenska</option>
                                        <option value="m">muska</option>
                                        <option value="n">nema</option>
                                    </select>

                                </div>
                            </div>

                            <div className="select-input">
                                <label htmlFor="selectInputs">Podkategorija kojoj pripada</label>
                                <select id='selectInputs' name="podkatpr" defaultValue={podkategorija_podkat_id} onChange={(e) => setPodkatpr(e.target.value)} >
                                    <option value=''>{podkat_naziv_sr}</option>
                                    {
                                        subData.map((item, idx) => {
                                            return (
                                                <option key={idx} value={item.podkat_id} >{item.podkat_naziv_sr}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                            <div className='form__inputs mt-2'>
                                <label className='color-success-muted' htmlFor="">Regularna Cena:</label>
                                <input type="text" defaultValue={proizvod_cena} onChange={(e) => setCenapr(e.target.value)} />
                            </div>
                            <div className='form__inputs'>
                                <label className='color-danger-muted' htmlFor="">Snizena Cena:</label>
                                <input type="text" defaultValue={proizvod_cena_snizena} onChange={(e) => setSnizenaCena(e.target.value)} />
                            </div>
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Boja Sr:</label>
                            <input type="text" defaultValue={proizvod_boja_sr} name="subkat_en" onChange={(e) => setBojaSr(e.target.value)} />

                            <label htmlFor="">Boja Er:</label>
                            <input type="text" defaultValue={proizvod_boja_en} name="subkat_en" onChange={(e) => setBojaEn(e.target.value)} />

                            <label htmlFor="">Opis proizvoda Sr</label>
                            <textarea className='p-1 mb-1' name="opis_sr" id="" cols="30" rows="6" defaultValue={proizvod_opis_sr} onChange={(e) => setOpisSr(e.target.value)}></textarea>

                            <label htmlFor="">Opis proizvoda En</label>
                            <textarea className='p-1' name="opis_en" id="" cols="30" rows="6" defaultValue={proizvod_opis_en} onChange={(e) => setOpisEn(e.target.value)}></textarea>
                        </div>

                        <div className='form__inputs'>
                            <label htmlFor="">Vreme kreiranja:</label>
                            <input type="text" placeholder={proizvod_vreme_dodat} name="subkat_en" disabled />

                            <label htmlFor="">Vreme izmene:</label>
                            <input type="text" placeholder={proizvod_vreme_izmene} name="subkat_en" disabled />

                            <label htmlFor="">Kreirao korisnik:</label>
                            <input type="text" placeholder={`${korisnik_ime} ${korisnik_prezime}`} disabled />

                            <div className='form__inputs-different'>
                                <div className='form__inputs mr-1'>
                                    <label htmlFor="">Pogledan puta:</label>
                                    <input type="text" placeholder={proizvod_pogledan} name="subkat_en" disabled />
                                </div>
                                <div className='form__inputs'>
                                    <label htmlFor="">Ocena proizvoda:</label>
                                    <input type="text" placeholder={proizvod_ocena} name="subkat_en" disabled />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div className='mt-1 mb-1'>
                    <label className='accent' htmlFor="">Slike proizvoda:</label>

                    <div className='details__products'>
                        <div className='details__products-img'>
                            {
                                splitSlike.map((img, idx) => {
                                    return (
                                        <div key={idx} className='details__products-img-content'>
                                            <img src={slike + img} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='mt-1 mb-1'>
                    <label className='accent' htmlFor="">Slike održavanje:</label>

                    <div className='details__products'>
                        <div className='details__products-img'>
                            {
                                splitSlikeOdr.map((img, idx) => {
                                    return (
                                        <div key={idx} className='details__products-img-content'>
                                            <img src={slikeOdr + img} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='details__options'>
                    <div className='details__options-inner mb-2'>
                        <RiCheckboxCircleLine className='icon-dash-success icon-main mr-1' onClick={updateProduct} />
                        <RiDeleteBinLine className='icon-dash-danger  icon-main'
                            onClick={() => deleteProduct(proizvod_id, proizvod_naziv_sr, proizvod_naziv_en)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsEdit