import React, { useState, useEffect } from 'react'
import OrdersList from '../../components/Dashboard/OrdersList/OrdersList'
import DetailsWindow from '../../components/Dashboard/DetailsWindow/DetailsWindow'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage'

import Modal from '../../components/Modal/Modal'

import { useAuthUser } from 'react-auth-kit';


const Orders = () => {

    const auth = useAuthUser()

    document.title = 'Order';

    const [asideMenu, setAsideMenu] = useState(false);
    const [detailsWindow, setDetailsWindow] = useState(false);
    const [orderOption, setOrderOption] = useState('');
    const [modal, setModal] = useState(false)
    const [modalId, setModalId] = useState('');


    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }
    }, [asideMenu]);


    if (auth().status == 'Admin' || auth().status == 'Urednik') {

        return (
            <div className='dashboard-container'>
                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />

                    <div className="main__section-nosearch category__layout-grid">
                        <OrdersList

                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            orderOption={orderOption}
                            setOrderOption={setOrderOption}
                            modal={modal}
                            setModal={setModal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <DetailsWindow

                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            orderOption={orderOption}
                            setOrderOption={setOrderOption}
                            modal={modal}
                            setModal={setModal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <Modal modal={modal} setModal={setModal} modalId={modalId} setDetailsWindow={setDetailsWindow} />
                    </div>
                </div>
            </div>
        )
    } else {
        return <ErrorPage notAdmin />
    }
}

export default Orders