import React from 'react'
import { RiSearch2Line } from 'react-icons/ri'

const Search = () => {
  return (
    <div className="search">
        <div className="search-input">
            <RiSearch2Line className='icon-small mr-1' />
            <input type="text" />
        </div>
    </div>
  )
}

export default Search