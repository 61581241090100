import React from 'react'
import api from '../../../api/api';
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Message from '../../Message/Message';
import Loader from '../../Loader/Loader';

import { useSelector, useDispatch } from 'react-redux';
import { getDashCategories, getUpdateDashCategories } from "../../../redux/features/categoriesDash/categoriesDashSlice";
import categoryAdd from '../../../utils/categoryAdd';
import editCategory from '../../../utils/editCategory';

import { useAuthUser } from 'react-auth-kit';


const Categories = () => {

  const auth = useAuthUser()

  // const [card, setCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // Initial state for creating category
  const [dataK, setDataK] = useState({
    kategorija_sr: '',
    kategorija_en: '',
  });


  // Redux Get category data
  // Redux Get Update category from state - datat send from CategoryList Component
  const categoryList = useSelector((state) => state.categoryDashList)
  const { loading, data, message, dataUpdate } = categoryList;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashCategories());
  }, [dispatch]);


  // Message je stilizovana komponenta Unutar Toast-a
  const notifyError = (odgovor) => {
    toast.error(<Message error={odgovor} />)
  }
  const notifySuccess = (odgovor) => {
    toast.success(<Message success={odgovor} />);
  }
  const notifyInfo = (odgovor) => {
    toast.info(<Message info={odgovor} />);
  }



  const handleChange = (e) => {
    setDataK({ ...dataK, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)

    const sendData = {
      kategorija_sr: dataK.kategorija_sr,
      kategorija_en: dataK.kategorija_en,
      user: auth().uid
    }

    categoryAdd(api, notifyError, notifyInfo, notifySuccess, sendData, dispatch, getDashCategories, setIsLoading, setDataK)
  }


  // Update category initial state
  const [katIdUpdate, setKatIdUpdate] = useState('');
  const [katSrUpdate, setKatSrUpdate] = useState('');
  const [katEnUpdate, setKatEnUpdate] = useState('');

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true)

    const sendData = {
      kat_id: katIdUpdate,
      kat_sr: katSrUpdate,
      kat_en: katEnUpdate,
      user: auth().uid
    }

    editCategory(api, sendData, notifyError, notifyInfo, notifySuccess, setKatSrUpdate, setKatEnUpdate, dispatch, setIsLoading, getDashCategories);
  }




  return (
    <div className='category__container'>
      <div className="category__container-header">
        {
          dataUpdate.category ? <h2>Izmeni Kategoriju</h2> : <h2>Dodaj Kategoriju</h2>
        }
      </div>

      <div className="category__container-inputs p-2">

        {
          dataUpdate.category ?

            <form onSubmit={handleUpdate}>
              <label htmlFor="">Novo Ime Kategorije Srpski</label>
              <input type="text" placeholder={dataUpdate.category.kat_naziv_sr} name="kat_sr" value={katSrUpdate} onChange={(e) => setKatSrUpdate(e.target.value)} />

              <label htmlFor="">Novo Ime Kategorije Engleski</label>
              <input type="text" placeholder={dataUpdate.category.kat_naziv_en} name="kat_en" value={katEnUpdate} onChange={(e) => setKatEnUpdate(e.target.value)} />

              <div className="category__container-inputs-content-buttons">
                {
                  isLoading ?
                    <button className='btn__dash-regular dash-button-info  mt-2 btn-minwidth'> <Loader /> </button> :
                    <button className='btn__dash-regular dash-button-info  mt-2 btn-minwidth' onClick={() => setKatIdUpdate(dataUpdate.category.kat_id)}>Izmeni Kategoriju</button>
                }
              </div>
            </form>

            :

            <form onSubmit={handleSubmit} >
              <label htmlFor="">Ime Kategorije Srpski</label>
              <input type="text" placeholder='Kategorija Sr' name="kategorija_sr" value={dataK.kategorija_sr} onChange={handleChange} />

              <label htmlFor="">Ime Kategorije Engleski</label>
              <input type="text" placeholder='Kategorija En' name="kategorija_en" value={dataK.kategorija_en} onChange={handleChange} />

              <div className="category__container-inputs-content-buttons">
                {
                  isLoading ? 
                    <button className='btn__dash-regular dash-button-success  mt-2 mr-1 btn-minwidth'> <Loader /> </button> :
                    <button className='btn__dash-regular dash-button-success  mt-2 mr-1 btn-minwidth'>Dodaj Kategoriju</button>
                }
              </div>
            </form>
        }
      </div>
    </div>
  )

}

export default Categories