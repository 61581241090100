import ErrorPage from '../pages/ErrorPage/ErrorPage';
import authsession from './authsession';

const categoryAdd = ( api, notifyError, notifyInfo, notifySuccess, sendData, dispatch, getDashCategories, setIsLoading, setDataK ) => {

  // const smiliesSesion = sessionStorage.getItem('SmiliesOnlineLog');

  // if(smiliesSesion){

    api({
        method: 'post',
        headers: { 'Authorization': authsession() },
        url: "categoryc.php?fun=add",
        data: sendData,
      })
  
        .then((response) => {
  
          if (response.data.uspesno) {
  
            dispatch(getDashCategories());
            setIsLoading(false)
            notifySuccess(response.data.uspesno);
            setDataK({ kategorija_sr: '', kategorija_en: '' })
  
          } else if (response.data.greska) {
  
            setIsLoading(false)
            notifyError(response.data.greska);
          } else if (response.data.info) {
  
            setIsLoading(false)
            notifyInfo(response.data.info);
          }
        })
  // }else{
  //   <ErrorPage nologin/>
  // }
}

export default categoryAdd